<template>
  <div>
    <div class="container">
      <ThePhotoSlider />
      <!--<TheModal />-->
    </div>
    <div class="footer-wrapper">
      <TheFooter />
    </div>
  </div>
</template>

<script>
import ThePhotoSlider from "@/components/ThePhotoSlider.vue";
import TheFooter from "@/components/TheFooter.vue";
//import TheModal from "@/components/TheModal.vue";
export default {
  components: {
    ThePhotoSlider,
    TheFooter,
    //TheModal,
  },
};
</script>

<style scoped>
.container {
  display: flex;
  background-color: rgba(27, 27, 27, 1);
}

.footer-wrapper {
  margin-top: 0;
  flex-shrink: 0;
}
</style>