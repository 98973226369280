<template>
  <header :style="{ backgroundColor: headerBackgroundColor }">
    <div class="container">
      <nav class="navigation-container">
        <div class="logo">
          <router-link to="/">
            <img src="../assets/logo.png" alt="logo" />
          </router-link>
        </div>
        <div class="mobile-toggle" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul :class="{ navigation: true, active: mobileMenuOpen }">
          <li>
            <router-link
              class="link"
              :to="{ name: 'Home' }"
              @click="closeMobileMenu"
              >Domov</router-link
            >
          </li>
          <li
            @mouseover="showCategories"
            @mouseleave="hideCategories"
            @click="toggleCategoriesMenu"
          >
            <router-link class="link" :to="{ name: 'Products' }"
              >Produkty</router-link
            >
            <transition name="slide-down">
              <ul
                v-show="showCategoriesMenu"
                class="categories-menu"
                @mouseover="showCategories"
                @mouseleave="hideCategories"
              >
                <li>
                  <router-link class="link" :to="{ name: 'Chlieb' }">
                    <img
                      :src="require(`@/assets/Categories/KATEGORIA_chleby.jpg`)"
                    />
                    Chlieb
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'Pecivo' }">
                    <img
                      :src="require(`@/assets/Categories/KATEGORIA_pecivo.jpg`)"
                    />
                    Pečivo
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'DrobneSladke' }">
                    <img
                      :src="
                        require(`@/assets/Categories/KATEGORIA_drobne_pecivo.jpg`)
                      "
                    />
                    Drobné sladké pečivo
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'Jemne' }">
                    <img
                      :src="
                        require(`@/assets/Categories/KATEGORIA_jemne_pecivo.jpg`)
                      "
                    />
                    Jemné pečivo
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'DrobneSlane' }">
                    <img
                      :src="
                        require(`@/assets/Categories/KATEGORIA_slane_pecivo.jpg`)
                      "
                    />
                    Drobné slané pečivo
                  </router-link>
                </li>
              </ul>
            </transition>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'About' }"
              >O nás</router-link
            >
          </li>
          <li>
            <router-link class="link" :to="{ name: 'Contacts' }"
              >Kontakt</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      headerBackgroundColor: "rgba(27, 27, 27, 1)",
      showCategoriesMenu: false,
      mobileMenuOpen: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    const menuLinks = document.querySelectorAll(".link");
    menuLinks.forEach((link) => {
      link.addEventListener("click", this.closeMobileMenu);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    const menuLinks = document.querySelectorAll(".link");
    menuLinks.forEach((link) => {
      link.removeEventListener("click", this.closeMobileMenu);
    });
  },
  methods: {
    toggleCategoriesMenu() {
      this.showCategoriesMenu = !this.showCategoriesMenu;
    },
    showCategories() {
      this.showCategoriesMenu = true;
    },
    hideCategories() {
      this.showCategoriesMenu = false;
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop === 0) {
        this.headerBackgroundColor = "rgba(27, 27, 27, 1)";
      } else {
        if (window.innerWidth > 768) {
          this.headerBackgroundColor = "rgba(27, 27, 27, 1)";
        }
      }
    },
    toggleMenu() {
      if (window.innerWidth <= 768) {
        this.mobileMenuOpen = !this.mobileMenuOpen;
        this.showCategoriesMenu = false;
      }
    },
    closeMobileMenu() {
      if (window.innerWidth <= 768) {
        this.mobileMenuOpen = false;
        this.showCategoriesMenu = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba($color: #1b1b1b, $alpha: 0.8);
  z-index: 99;
  transition: all 0.3s ease-in-out;
  border-bottom: 3px solid #f2a900;
}

.navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

nav {
  padding: 12px 0;
  transition: 0.5s ease-in-out;
  margin: 0 auto;
  width: 90%;
  ul,
  .link {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      text-transform: uppercase;
      margin: 0 10px;
    }
    .link {
      font-size: 18px;
      font-family: Montserrat;
      transition: 0.5s ease-in-out;
      font-weight: 500;
      color: #fff;
      text-decoration: none;
      align-self: center;
      cursor: pointer;
      &:hover {
        color: #f2a900;
      }
    }
  }
  .underlink {
    list-style-type: circle;
    font-size: 12px;
    font-family: Montserrat;
    transition: 0.5s ease-in-out;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    align-self: center;
    cursor: pointer;
    &:hover {
      color: #f2a900;
    }
  }
}

.logo {
  margin-right: 20px;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    transition: 0.5s ease-in-out;
  }
}

.categories-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  position: absolute;
  top: 100%;
  text-decoration: none;
  transition: color 0.3s;
  background-color: rgba($color: #ffffff, $alpha: 1);
  padding: 10px;
  color: rgba($color: #1b1b1b, $alpha: 1);
  white-space: normal;
  max-width: 650px;
  border: 3px solid #f2a900;
  border-top: 0px;
  .link,
  .underlink {
    color: rgba($color: #1b1b1b, $alpha: 1);
  }
  img {
    width: 70px;
    height: auto;
  }
}

.categories-menu:hover {
  color: #fff;
}

.slide-down-enter-active {
  transition: all 0.5s;
  opacity: 0;
}

.slide-down-enter-to {
  max-height: 500px;
  opacity: 1;
}

.slide-down-leave-active {
  transition: all 0.5s;
  opacity: 0;
  transition-delay: 0.8s;
}

.mobile-toggle {
  display: none;
}

.navigation.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgba($color: #1b1b1b, $alpha: 1);
  z-index: 99;
}

.navigation.active li {
  margin: 10px 0;
}

.navigation.active .link,
.navigation.active .underlink {
  color: #fff;
}

.navigation.active .link:hover,
.navigation.active .underlink:hover {
  color: #f2a900;
}

@media (max-width: 768px) {
  .logo {
    margin-right: auto;
  }

  .mobile-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 30px;
    height: 22px;
    cursor: pointer;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transition: transform 0.3s;
    }

    &.active span:nth-child(2) {
      transform: translateX(-100%);
      opacity: 0;
    }

    &.active span:nth-child(1) {
      transform: rotate(45deg) translateY(5px);
    }

    &.active span:nth-child(3) {
      transform: rotate(-45deg) translateY(-5px);
    }
  }

  .navigation {
    border-top: 3px solid #f2a900;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgba($color: #1b1b1b, $alpha: 0.8);
    z-index: 99;
    padding: 10px;
  }

  .navigation li {
    margin: 10px 0;
  }

  .navigation .link,
  .navigation .underlink {
    color: #fff;
  }

  .navigation .link:hover,
  .navigation .underlink:hover {
    color: #f2a900;
  }

  @media (max-width: 768px) {
    .categories-menu {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: static;
      top: initial;
      text-decoration: none;
      transition: color 0.3s;
      background-color: rgba($color: #1b1b1b, $alpha: 1);
      padding: 10px;
      color: rgba($color: #1b1b1b, $alpha: 1);
      white-space: normal;
      max-width: 100%;
      border: 3px solid #f2a900;
      .link,
      .underlink {
        color: rgba($color: #1b1b1b, $alpha: 1);
      }
      img {
        width: 0;
        height: auto;
      }
    }
  }
}
</style>
