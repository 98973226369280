<template>
  <photo-slider
    :slides="slides"
    :controls="controls"
    :indicators="indicators"
    :interval="interval"
    :description="description"
  ></photo-slider>
</template>

<script>
import PhotoSlider from "./PhotoSliderComponents/PhotoSlider.vue";
import slider1 from "../assets/PhotoSlider/UVODNE_mix.jpg";
import slider2 from "../assets/PhotoSlider/UVODNE_kvasok.jpg";
import slider3 from "../assets/PhotoSlider/UVODNE_ruky.jpg";
import slider4 from "../assets/PhotoSlider/UVODNE_tradicne.jpg";
import slider5 from "../assets/PhotoSlider/UVODNE_suroviny.jpg";

export default {
  components: { PhotoSlider },
  data: () => ({
    slides: [slider1, slider2, slider3, slider4, slider5],
    description: [
      {
        title: "Mix",
        text: "V našej tradičnej pekárni sa venujeme výrobe rozmanitého sortimentu slaného i sladkého pečiva, chleba a ďalších lahôdok. Naša dlhoročná tradícia a vášeň pre remeselné pečenie sa premieta do každého kúsku, ktorý opúšťa naše pece.",
        highlight: "tradičnej pekárni",
      },
      {
        title: "Kvasok",
        text: "Naše kváskové produkty sú bez pridania droždia, vyrobené len z čistého kvásku. Sortiment kváskových produktov neustále rozširujeme, pretože vieme o ich výhodách pre naše zdravie.",
        highlight: "čistého kvásku",
      },
      {
        title: "Ruky",
        text: "Snažíme sa o zachovanie tradičnej remeselnej výroby. Každý náš chlieb, koláč či pečivo prešlo rukami našich šikovných pekárov.",
        highlight: "remeselnej výroby",
      },
      {
        title: "Tradičné",
        text: "Naša vášeň pre zachovanie tradícií sa prejavuje v každom kroku procesu. Aj keď sme otvorení inováciám a novým trendom, naše srdce zostáva verné tradíciám. Veríme, že spojenie starých postupov s novými poznatkami prináša najlepšie výsledky.",
        highlight: "zachovanie tradícií",
      },
      {
        title: "Suroviny",
        text: "Záleží nám na kvalite surovín. Používame len kvalitné džemy, poctivý slovenský tvaroh, mak a orechy, ktoré si melieme sami pred každou výrobou.",
        highlight: "kvalite surovín",
      },
    ],
    controls: true,
    indicators: true,
    interval: 4000,
  }),
};
</script>

<style scoped>
.photo-slider {
  position: relative;
  width: 100%;
}

.photo-slider .slide {
  position: relative;
}

.photo-slider .slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
  opacity: 0.5; /* Adjust the opacity value as needed */
  transition: opacity 0.3s ease;
}

.photo-slider .slide:hover::before {
  opacity: 0.7; /* Adjust the opacity value as needed */
}

.highlighted {
  color: #f2b46d;
}
</style>
