<template>
  <div class="product-page">
    <div
      class="product-grid"
      :class="[gridClass, { hidden: products.length === 0 }]"
    >
      <router-link
        class="product-item"
        v-for="product in products"
        :key="product.id"
        :to="'/product/' + product.id"
        v-animate-visibility
      >
        <div class="image-container">
          <img
            class="product-image"
            :src="getProductImagePath(product)"
            alt="Product Image"
          />
        </div>
        <h1>{{ product.druh }}</h1>
        <p class="product-description">{{ product.produkt }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    products: Array,
  },
  data() {
    return {
      gridClass: "product-three",
    };
  },
  mounted() {
    this.updateGridClass();
    window.addEventListener("resize", this.updateGridClass);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateGridClass);
  },
  methods: {
    updateGridClass() {
      const containerWidth = this.$el.clientWidth;
      const maxImageWidth = 250; // Maximum width of the image

      // Calculate the number of columns based on the container width and the maximum image width
      const numColumns = Math.floor(containerWidth / maxImageWidth);

      // Update the gridClass based on the number of columns
      if (numColumns >= 3) {
        this.gridClass = "grid-three";
      } else if (numColumns === 2) {
        this.gridClass = "grid-two";
      } else {
        this.gridClass = "grid-one";
      }
    },
    getProductImagePath(product) {
      if (product.fotka) {
        try {
          return require(`@/assets/Products/${product.fotka}`); // Load image dynamically
        } catch (e) {
          return require(`@/assets/Products/EMPTY.jpg`); // Provide a default image path or handle the case where product.image is undefined
        }
      } else {
        return require(`@/assets/Products/EMPTY.jpg`); // Provide a default image path or handle the case where product.image is undefined
      }
    },
  },
};
</script>

<style>
.product-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  padding: 60px 0; /* Add padding to separate content from the background */
  min-height: 100%;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Adjusts the column width based on content and maintains a minimum width of 250px */
  grid-gap: 3em; /* Adds a 1em gap between the grid items */
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: rgba(27, 27, 27, 1);
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 10px;
  border: 3px solid #f2a900;
  margin: 60px;
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none; /*Added to remove the underline */
  max-width: 100%; /* Remove the max-width property to allow the container to adjust based on the image width */
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
}

.image-container {
  animation-name: move;
  animation-duration: 2s;
}

@keyframes move {
  0% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0px);
  }
}

.product-image:hover {
  box-shadow: 0px 0px 0px 3px #f2a900;
  transition: 0.3s;
}

.product-item h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #e7e5e5;
  padding-top: 20px;
}

.product-item p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #e7e5e5;
  word-wrap: break-word; /* Added to allow text wrapping */
  text-align: center;
  margin: 0; /* Remove default margin to avoid extra spacing */
}

.product-item.animate-product {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
}

.product-item.animate-product.visible {
  opacity: 1;
  transform: translateY(0);
}

.hidden {
  display: none;
}
</style>
