<template>
  <div class="aboutpage">
    <div class="container">
      <div class="text-image">
        <div class="text-container">
          <div class="content">
            <p>
              V našej pekárni sa pečie už od roku 1998. Celé roky sa snažíme o
              zachovanie tradičného pekárenského remesla. Pečieme výnimočný
              kváskový chlieb, ktorý si zamilujete už na prvé ochutnanie.
            </p>
            <div class="image-container">
              <img src="../assets/About/ABOUT_1.jpg" alt="pekar" />
            </div>
          </div>
        </div>
      </div>
      <div class="text-image">
        <div class="text-container">
          <div class="content">
            <div class="image-container">
              <img src="../assets/About/ABOUT_3.jpg" alt="pekar" />
            </div>
            <p>
              Naši pekári robia každý kus pečiva ručne a s láskou. Používame len
              tie najkvalitnejšie suroviny. U nás nenájdete plnky z makových
              šupiek, orechy nie sú nahradené strúhankou a v cestách nie sú
              priemyselné margaríny. Koláče voňajú po čerstvom masle, ovocí a
              kvalitných džemoch.
            </p>
          </div>
        </div>
      </div>
      <div class="text-image">
        <div class="text-container">
          <div class="content">
            <p>
              Veríme, že naša práca má stále zmysel a budúcnosť aj napriek
              širokej ponuke priemyselne vyrobeného pečiva. Veď krajec voňavého
              chleba, či poctivé domáce koláče boli a navždy budú súčasťou nášho
              života.
            </p>
            <div class="image-container">
              <img src="../assets/About/ABOUT_2.jpg" alt="pekar" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrapper">
      <the-footer />
    </div>
  </div>
</template>

<script>
import TheFooter from "../components/TheFooter.vue";

export default {
  components: {
    TheFooter: TheFooter,
  },
};
</script>

<style scoped>
.aboutpage {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.container {
  background-image: url("../assets/pozadie.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 50px;
  align-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.text-image {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  background-color: rgba(27, 27, 27, 0.9);
  color: #fff;
  border-radius: 15px;
  padding: 30px;
  border: 3px solid #f2a900;
  width: 70%;
}

.content {
  display: flex;
  justify-content: space-between;
}

.text-container {
  display: flex;
  text-align: center;
}

p {
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 1rem;
  text-align: center;
  font-family: "Montserrat";
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  height: 200px;
  border-radius: 10px;
}

.footer-wrapper {
  margin-top: 0;
  flex-shrink: 0;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .text-image {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .content {
    flex-direction: column; /* Stack the image and text vertically on mobile */
  }

  .image-container {
    margin-bottom: 10px;
  }
}

/* Media query for PC view */
@media (min-width: 769px) {
  .text-image {
    flex-direction: row;
  }

  .image-container {
    margin-right: 20px;
  }
}
</style>