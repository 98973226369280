import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/HomePage.vue";
import About from "../views/AboutPage.vue";
import Products from "../views/ProductsPage.vue";
import Contacts from "../views/ContactsPage.vue";
import Chlieb from "../views/Products/ChliebPage.vue";
import Pecivo from "../views/Products/PecivoPage.vue";
import Sladke from "../views/Products/DrobneSladkePage.vue";
import Slane from "../views/Products/DrobneSlanePage.vue";
import Jemne from "../views/Products/JemnePage.vue";
import ProductDetail from "../views/ProductDetailPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/chlieb",
    name: "Chlieb",
    component: Chlieb,
  },
  {
    path: "/pecivo",
    name: "Pecivo",
    component: Pecivo,
  },
  {
    path: "/drobne-sladke-pecivo",
    name: "DrobneSladke",
    component: Sladke,
  },
  {
    path: "/drobne-slane-pecivo",
    name: "DrobneSlane",
    component: Slane,
  },
  {
    path: "/jemne-pecivo",
    name: "Jemne",
    component: Jemne,
  },
  {
    path: "/product/:productId",
    name: "ProductDetail",
    component: ProductDetail,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  mode: "hash",
});

export default router;
