<template>
  <div id="container">
    <div id="contacts">
      <div id="contacts--contact">
        <p>{{ address }}</p>
        <p>{{ city }}</p>
        <p>{{ country }}</p>
      </div>
      <div id="contacts--social">
        <a href="https://www.facebook.com/" target="_blank"
          ><i class="fab fa-facebook-f"
            ><img src="../assets/facebook.png" alt="facebook" /></i
        ></a>
        <a href="https://www.instagram.com/" target="_blank"
          ><i class="fab fa-instagram"
            ><img src="../assets/instagram.png" alt="instagram" /></i
        ></a>
      </div>
    </div>
    <div id="mentions">
      <p>{{ mention }}</p>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const address = "Závodná 78/C";
    const city = "821 07 Bratislava";
    const country = "Slovensko";
    const mention = "© 2023 Pekáreň Biskupice, všetky práva vyhradené.";
    return {
      address,
      city,
      country,
      mention,
    };
  },
};
</script>



<style scoped>
#container {
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  color: white;
  background-color: rgb(27, 27, 27);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70px;
  border-top: 3px solid #f2a900;
  padding-top: 25px;
  padding-bottom: 5px;
}
#contacts {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
#contacts--contact {
  text-align: right;
}
#contacts--social {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
}
#contacts--social img {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  border: 0px;
}
#mentions p {
  font-size: 0.8rem;
  margin-top: 10px;
}
</style>