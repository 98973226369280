<template>
  <div id="app">
    <navigation-header />
    <router-view />
  </div>
</template>

<script>
import TheNavigation from "./components/TheNavigation.vue";
export default {
  name: "App",
  components: {
    NavigationHeader: TheNavigation,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7e5e5;
  width: 100%;
  height: 100%;
}

navigation-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
</style>
